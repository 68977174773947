import '../styles/Categorypage.css'
import Logo from "../assets/content_hub_banner.jpg";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getContent} from "../api/api";
import Footer from "./Footer";
import Content from "./Content";
import ArticleCategoryPage from "./ArticleCategoryPage";
import TopMenu from "./TopMenu";
import '../App.css'


const CategoryPage = () => {
    const [content, setContent] = useState([]);
    const {category} = useParams();

    const slicePost = function (data) {
        for (let i=0; i < Object.keys(data).length; i++){
            const index = Object.keys(data)[i];
            data[index].content = data[index].content.slice(0,180) + "[...]"
        }
        return data
    };

    useEffect(()=>{
        (async ()=>{
            const data = await getContent();
            const newContent = slicePost(data);
            setContent(newContent);
        })();
    },[category]);

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <div className="search-banner-container">
            <div>
                <ul className="search-banner">
                    <header><span>SEARCH FOR</span><span>Sponsored</span></header>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLife%20Insurance%20Online" target="_blank">Life Insurance Online</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLose%20Weight%20Now" target="_blank">Lose Weight Now</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DHome%20Loan%20Refinance" target="_blank">Home Loan Refinance</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLosing%20Belly%20Fat%20Exercises%20For%20Women" target="_blank">Losing Belly Fat Exercises For Women</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DVacation%20Packages" target="_blank">Vacation Packages</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLife%20Insurance" target="_blank">Life Insurance</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DCheap%20Hotels" target="_blank">Cheap Hotels</a></li>
                    <li><a className="search-banner-inner" href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DBelly%20Wrap%20For%20Weight%20Loss" target="_blank">Belly Wrap For Weight Loss</a></li>
                </ul>
            </div>
            </div>
            <div className='category-title'>CATEGORY: {category.toUpperCase()}</div>
            <Content>
                {content ?
                    Object.keys(content).map(key => {
                        if (content[key].category === category.toLowerCase()) {
                            return <ArticleCategoryPage key={key} id={content[key].id} title={content[key].title}
                                                 content={content[key].content} image={content[key].image}/>
                        }
                    })
                : ''}
            </Content>
            <Footer/>
        </div>
    );
};

export default CategoryPage